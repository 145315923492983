$accent-colour: #fa6600;
$highlighted-background-colour: #2e3031;
$front-background-colour: #212222;
$back-background-colour: black;
$header-text-colour: white;
$body-text-colour: rgb(199, 199, 199);
$light-text-colour: darken($body-text-colour, 15);
$main-border-radius: 4px;

$icon-colour-alternative: #e3e3e3;

$card-content-background-colour: $front-background-colour;
$sidebar-background-colour: $front-background-colour;
$header-background-colour: $front-background-colour;
$link-colour: #fa6600;
$heading-font-family: 'Noto Sans', 'Roboto', 'Segoe', 'Candara', 'Calibri',
    'Arial', sans-serif;
$body-font-family: 'Noto Sans', 'Roboto', 'Segoe', 'Candara', 'Calibri', 'Arial',
    sans-serif;

@use 'sass:color';

@forward '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $accent-colour,
    $body-background: $back-background-colour,
    $body-font-family: $body-font-family,
    $body-text-colour: $body-text-colour,
    $Link-text-colour: $link-colour,
    $Link-title-text-colour: $accent-colour,
    $heading-font-family: $heading-font-family,
    $heading-text-colour: $accent-colour,
    $well-background: $highlighted-background-colour,
    $input-background: $highlighted-background-colour,
    $input-border: 1px solid $highlighted-background-colour,
    $input-background-disabled: color.scale($highlighted-background-colour, $alpha: -80%),
    $input-text-colour-disabled: $light-text-colour,
    $app-background: $back-background-colour,
    $document-background: white,
    $light-text-colour: $light-text-colour,
    $success-text-colour: #379d37,
    $error-text-colour: #e65b58,
    $light-border: 1px solid #373838,
    $Sidebar-background: $sidebar-background-colour,
    $Sidebar-light-text-colour: $light-text-colour,
    $Button-background: $accent-colour,
    $Button-text-colour: black,
    $Button-background-outlined: black,
    $Button-text-colour-danger: white,
    $Panel-background: $card-content-background-colour,
    $Panel-header-text-colour: $header-text-colour,
    $Table-header-text-colour: $header-text-colour,
    $Panel-header-font-weight: normal,
    $Panel-header-background: #191818,
    $Panel-info-background: #422704,
    $Panel-header-padding: 12px,
    $Panel-border: 2px solid $front-background-colour,
    $Panel-border-radius: $main-border-radius,
    $IconButton-icon-colour: $accent-colour,
    $IconButton-icon-colour-disabled: color.scale($accent-colour, $alpha: -80%),
    $Header-background: $header-background-colour,
    $ContentHeader-title-font-size: 24px,
    $ContentHeader-title-text-colour: $header-text-colour,
    $VerticalTabBar-font-size: 18px,
    $VerticalTabBar-text-colour: $header-text-colour,
    $Table-header-font-size: 15px,
    $Table-header-box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.1), // Make sure outlines show on tables
    $Table-background: $card-content-background-colour,
    $VehicleHistoryPopover-header-font-size: 18px,
    $VehicleHistoryList-details-font-size: 14px,
    $NewLookPanel-background: $highlighted-background-colour,
    $SearchBox-background: $highlighted-background-colour,
    $DocumentLabel-text-colour: $front-background-colour,
    $MessageTableRow-read-background: darken($front-background-colour, 2),
    $HeaderSearch-background: $highlighted-background-colour,
    $TickBox-sidebar-ticked-colour: $accent-colour,
    $badge-background: #feda7e,
    $badge-text-colour: black,
    $badge-font-weight: bold,
    $VehicleTabs-font-family: $heading-font-family,
    $VehicleTabs-text-colour: $body-text-colour,
    $VehicleTabs-text-colour-hover: $header-text-colour,
    $VehicleTabs-text-colour-selected: $accent-colour,
    $DocumentLabel-background-new: $accent-colour,
    $DocumentLabel-text-colour-new: black,
    $LanguageSelectItem-selected-text-colour: black,
    $VehiclePurchaseSubscriptionPanel-background-colour: $accent-colour,
    $VehiclePurchaseSubscriptionPanel-link-text-colour: black,
    $VehiclePurchaseSubscriptionPanel-link-font-size: 1rem,
    $VehiclePurchaseSubscriptionPanel-link-font-weight: bold,
);

@use '~@cognitranlimited/itis-web/dist/styles';

@use '~@cognitranlimited/itis-web/dist/styles/campaigns' with (
    $CampaignsBadge-background: firebrick,
    $CampaignsBadge-text-colour: white
);

// Noto Sans Font
@import '~@fontsource/noto-sans/400.css';
@import '~@fontsource/noto-sans/400-italic.css';
@import '~@fontsource/noto-sans/700.css';
@import '~@fontsource/noto-sans/700-italic.css';

/**
 * Temporary overrides (these should have a corresponding core case to avoid accumulating styling overrides)
**/

// Temporary override for recent VIN dropdown
.MuiPaper-root {
    background-color: $front-background-colour !important;
    color: $header-text-colour !important;
}

.MessageTable .MuiCheckbox-root {
    color: $icon-colour-alternative !important;
}

.Table tbody tr:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05); // make sure table borders are visible
}

.DeleteAccountTab h3 {
    color: $header-text-colour !important;
}

.MuiMenuItem-root.MuiButtonBase-root {
    background: $front-background-colour;
}

.Expander {
    &-expand {
        min-width: 55px;
    }

    &-header {
        height: inherit;
        min-height: 50px;
    }

    h4 {
        padding: 15px 0px;
    }
}

.SubscriptionLabel {
    text-align: center;
    white-space: nowrap;
}

.Dialogue-header {
    min-height: inherit;
}

.MuiNativeSelect-select:not([multiple]) {
    option,
    optgroup {
        background-color: $highlighted-background-colour !important;
    }
}

.MuiMenuItem-root svg {
    color: $accent-colour;
}

svg.MuiNativeSelect-icon {
    color: $body-text-colour;
}

.VehicleHistoryList a {
    width: 100% !important;
}

.VehicleHistoryList img {
    height: auto !important;
}

.DocumentSearchFilter {
    &-default {
        color: styles.$SearchBox-placeholder-colour;
    }

    .MuiSelect-select {
        color: styles.$SearchBox-text-colour;
    }

    svg.MuiSelect-icon {
        color: styles.$SearchBox-icon-colour;
    }
}

.NewLookPanel h3 {
    color: $header-text-colour;
}

.DocumentMessageScreen-container {
    background: $back-background-colour;
}

.DocumentShareLinkUserSearch-tag {
    color: white;
}

.MuiAutocomplete-endAdornment .MuiSvgIcon-root {
    color: $body-text-colour;
}

.MuiAutocomplete-noOptions {
    color: white !important;
}

.DocumentInfoDialogue-actions {
    background-color: black !important;
}

.DocumentSearchFilter-default {
    color: #989696 !important;
}

.MuiToggleButton-primary:not(.Mui-selected) {
    color: white !important;
}

.MuiDayCalendar-weekDayLabel:not(.Mui-disabled),
.MuiPickersDay-root:not(.Mui-disabled),
.MuiPickersCalendarHeader-switchViewButton,
.MuiPickersArrowSwitcher-button:not(.Mui-disabled) {
    color: white !important;
}

.MuiPickersDay-root.Mui-disabled,
.MuiPickersArrowSwitcher-button.Mui-disabled,
.MuiPickersYear-yearButton.Mui-disabled {
    color: gray !important;
}

.MuiPickersDay-root.MuiPickersDay-today {
    border: 1px solid white !important;
}
